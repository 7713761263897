import {
	subscriptionIsExpiredLast6Months,
	subscriptionIsExpiring
} from "@/helpers/device-subscription.helper.js";
import i18n from "@/lang/lang";

class SubscriptionDetails {
	constructor(subscriptionDetails) {
		this.ratePlanOrderId = subscriptionDetails.ratePlanOrderId;
		this.sellerOrganizationId = subscriptionDetails.sellerOrganizationId;
		this.sellerOrganizationName =
			subscriptionDetails.sellerOrganizationName;
		this.buyerOrganizationId = subscriptionDetails.buyerOrganizationId;
		this.buyerOrganizationName = subscriptionDetails.buyerOrganizationName;
		this.ownerOrganizationId = subscriptionDetails.ownerOrganizationId;
		this.ownerOrganizationName = subscriptionDetails.ownerOrganizationName;
		this.ratePlanDetails = {
			type: subscriptionDetails.ratePlanDetails.type,
			ratePlan: subscriptionDetails.ratePlanDetails.ratePlan,
			durationMonths: subscriptionDetails.ratePlanDetails.durationMonths,
			addons: subscriptionDetails.ratePlanDetails.addons,
			options: subscriptionDetails.ratePlanDetails.options,
			range: subscriptionDetails.ratePlanDetails.range
		};

		if (subscriptionDetails.ratePlanDetails.config) {
			this.ratePlanDetails.config = {
				displayName:
					subscriptionDetails.ratePlanDetails.config.displayName,
				numberOfDataPointsPer10Minutes:
					subscriptionDetails.ratePlanDetails.config
						.numberOfDataPointsPer10Minutes,
				numberOfAdditionalDataPointsPer10Minutes:
					subscriptionDetails.ratePlanDetails.config
						.numberOfAdditionalDataPointsPer10Minutes,
				propertiesMaxNumber:
					subscriptionDetails.ratePlanDetails.config
						.propertiesMaxNumber,
				ratePlanDeviceType:
					subscriptionDetails.ratePlanDetails.config
						.ratePlanDeviceType,
				authorizedProtocols:
					subscriptionDetails.ratePlanDetails.config
						.authorizedProtocols,
				propertiesLimitByTransmission:
					subscriptionDetails.ratePlanDetails.config
						.propertiesLimitByTransmission,
				frequency: subscriptionDetails.ratePlanDetails.config.frequency
			};
		}
	}
}

class Subscription {
	constructor(subscription) {
		this.current = subscription.current
			? new SubscriptionDetails(subscription.current)
			: null;
		this.next = subscription.next
			? new SubscriptionDetails(subscription.next)
			: null;
		this.startDate = subscription.startDate;
		this.endDate = subscription.endDate;
		this.isExpired = subscription.isExpired;
	}

	get formattedStartDate() {
		return this._formatDate(this.startDate);
	}

	get formattedEndDate() {
		return this._formatDate(this.endDate);
	}

	get qttAddons() {
		return this.current?.ratePlanDetails?.addons || null;
	}

	// TODO: use $dayjs to format date
	_formatDate(date) {
		if (!date || date === null || date === undefined) {
			return null;
		}

		const pattern = /(\d{4})-(\d{2})-(\d{2})/;
		if (!date || !date.match(pattern)) {
			return null;
		}
		return date.replace(pattern, "$3/$2/$1");
	}
}

class ConnectivityStatus {
	constructor(connectivity) {
		this.connectionStatusInfo = {
			status: connectivity.connectionStatusInfo.status
		};
		this.currentDataUplink = {
			status: connectivity?.currentDataUplink?.status,
			startAt: connectivity?.currentDataUplink?.startAt,
			endAt: connectivity?.currentDataUplink?.endAt
		};
	}

	get status() {
		return this.connectionStatusInfo?.status;
	}

	get dataUplinkStatus() {
		return this.currentDataUplink?.status;
	}
}

export default class Device {
	constructor(deviceResponseFromApi) {
		this.deviceId = deviceResponseFromApi.deviceId;
		this.hardwareId = deviceResponseFromApi.hardwareId;
		this.lifecycle = deviceResponseFromApi.lifecycle;
		this.name = deviceResponseFromApi.name;
		this.connectivityStatus = deviceResponseFromApi.connectivityStatus
			? new ConnectivityStatus(deviceResponseFromApi.connectivityStatus)
			: null;
		this.subscription = deviceResponseFromApi.subscription
			? new Subscription(deviceResponseFromApi.subscription)
			: null;
		this.type = deviceResponseFromApi.type;
		this.description = deviceResponseFromApi.description;
		this.organizationId = deviceResponseFromApi.organizationId;
		this.tenantId = deviceResponseFromApi.tenantId;
		this.externalId = deviceResponseFromApi.externalId;
		this.openAlarms = deviceResponseFromApi.openAlarms || [];
		this.tags = deviceResponseFromApi.tags || {};

		this.isControlRange =
			deviceResponseFromApi.subscription?.current?.ratePlanDetails
				?.range === "CONTROL";

		this.rawDeviceFromApi = deviceResponseFromApi;
	}

	get raw() {
		return this.rawDeviceFromApi;
	}

	get status() {
		return this.connectivityStatus?.connectionStatusInfo?.status;
	}

	get qttAddons() {
		return this.subscription?.qttAddons;
	}

	get isActive() {
		return ["ACTIVATED"].includes(this.lifecycle);
	}

	get isInactive() {
		// FACTORY and READY shouldn't be displayed to user as it doesn't have an organization assigned yet (but in staging we have some cases like that, so display it)
		return ["FACTORY", "READY", "SHIPPED"].includes(this.lifecycle);
	}

	get isSubscriptionExpiring() {
		return subscriptionIsExpiring(this?.subscription?.endDate);
	}

	get isSubscriptionExpired() {
		return this.subscription?.isExpired;
	}

	get isSubscriptionExpiredInLast6Months() {
		return subscriptionIsExpiredLast6Months(this?.subscription?.endDate);
	}

	get isClassicalHub() {
		return this.type === "CLASSICAL_HUB" || this.lifecycle === "HUB";
	}

	get isTower() {
		return ["TOWER", "BOX"].includes(this.type);
	}

	get isBridge() {
		return ["BRIDGE", "HUB"].includes(this.type);
	}

	get humanFriendlyTypeName() {
		switch (this.type) {
			case "TOWER":
			case "BOX":
				return i18n.t("client-name-box");
			case "BRIDGE":
			case "HUB":
			case "CLASSICAL_HUB":
				return i18n.t("client-name-hub");
			default:
				return null;
		}
	}

	get hasOpenAlarms() {
		return (this.openAlarms || []).length > 0;
	}
}
