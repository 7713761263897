import { useXlsx } from "@/composables/useXlsx";
import {
	downloadFile,
	uppercaseFirstLetter
} from "@/helpers/functions.helper.js";
import { Protocols } from "@/helpers/protocols.helper";
import { useDeviceStore } from "@/store/device";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import WizardConfigurationService from "@/services/v1/WizardConfiguration.service";
import { isLorawanSystemCodec } from "@/helpers/box-configuration.helper.js";
import {
	allowedCsvFileHeaders as lorawanAllowedCsvFileHeaders,
	requiredCsvFileHeaders as lorawanRequiredCsvFileHeaders,
	formatLine as lorawanFormatLine
} from "@/components/ws-dropzone/helpers/objects/lorawan-import-csv.helpers.js";

import {
	allowedCsvFileHeaders as modbusAllowedCsvFileHeaders,
	requiredCsvFileHeaders as modbusRequiredCsvFileHeaders,
	formatLine as modbusFormatLine
} from "@/components/ws-dropzone/helpers/objects/modbus-csv.helpers.js";

import {
	allowedFileHeaders as equipmentsAllowedFileHeaders,
	requiredFileHeaders as equipmentsRequiredFileHeaders,
	headerToApiKeys as equipmentsHeaderToApiKeys,
	formatLine as equipmentsFormatLine,
	formatToFile as equipmentFormatToFile
} from "@/components/ws-dropzone/helpers/objects/equipments-import.helpers.js";

import {
	allowedFileHeaders as propertiesAllowedFileHeaders,
	requiredFileHeaders as propertiesRequiredFileHeaders,
	headerToApiKeys as propertiesHeaderToApiKeys,
	formatLine as propertiesFormatLine,
	formatToFile as propertiesFormatToFile
} from "@/components/ws-dropzone/helpers/objects/properties-import.helpers.js";

import {
	allowedFileHeaders as redirectedPropertiesAllowedFileHeaders,
	requiredFileHeaders as redirectedPropertiesRequiredFileHeaders,
	headerToApiKeys as redirectedPropertiesHeaderToApiKeys,
	formatLine as redirectedPropertiesFormatLine,
	formatToFile as redirectPropertiesFormatToFile
} from "@/components/ws-dropzone/helpers/objects/redirected-properties-import.helpers.js";
import { useLogMonitoring } from "@/plugins/user-console-monitoring/useLogMonitoring";

export function useExportBulkConfiguration(props) {
	const { notify } = useLogMonitoring();

	const deviceStore = useDeviceStore();
	const mergedConfigEquipments = computed(
		() => deviceStore.mergedConfigEquipments
	);
	const mergedConfigProperties = computed(
		() => deviceStore.mergedConfigProperties
	);
	const draftConfig = computed(() => deviceStore.draftConfig);
	const identifier = computed(() => props.selectedExport);
	const config = computed(() => {
		switch (identifier.value) {
			case "lorawan":
				return {
					allowedHeaders: lorawanAllowedCsvFileHeaders,
					requiredHeaders: lorawanRequiredCsvFileHeaders,
					formatToPreview: (arr) =>
						arr.map((line) => lorawanFormatLine(line))
				};
			case "modbus":
				return {
					allowedHeaders: modbusAllowedCsvFileHeaders,
					requiredHeaders: modbusRequiredCsvFileHeaders,
					formatToPreview: (arr) =>
						arr.map((line) => modbusFormatLine(line))
				};
			case "equipments":
				return {
					allowedHeaders: equipmentsAllowedFileHeaders,
					requiredHeaders: equipmentsRequiredFileHeaders,
					headerToApiKeys: equipmentsHeaderToApiKeys,
					formatToPreview: (arr) =>
						arr.map((line) => equipmentsFormatLine(line)),
					formatToFile: equipmentFormatToFile
				};
			case "properties":
				return {
					allowedHeaders: propertiesAllowedFileHeaders,
					requiredHeaders: propertiesRequiredFileHeaders,
					headerToApiKeys: propertiesHeaderToApiKeys,
					formatToPreview: (arr) =>
						arr.map((line) => propertiesFormatLine(line)),
					formatToFile: propertiesFormatToFile
				};
			case "redirected-properties":
				return {
					allowedHeaders: redirectedPropertiesAllowedFileHeaders,
					requiredHeaders: redirectedPropertiesRequiredFileHeaders,
					headerToApiKeys: redirectedPropertiesHeaderToApiKeys,
					formatToPreview: (arr) =>
						arr.map((line) => redirectedPropertiesFormatLine(line)),
					formatToFile: redirectPropertiesFormatToFile
				};
			default:
				notify("No identifier set");
				return {
					allowedHeaders: [],
					requiredHeaders: [],
					formatToPreview: () => ({}),
					formatToFile: () => ({})
				};
		}
	});

	const { t: $t } = useI18n();
	const xlsx = useXlsx();

	const hiddenColumns = computed(() =>
		props.selectedExport === "properties"
			? ["equipmentCodecId", "propertyCodecId", "equipmentId"]
			: null
	);

	async function dataToExport() {
		let data = [];
		switch (props.selectedExport) {
			case "equipments":
				data = mergedConfigEquipments.value.filter(
					(equipment) => equipment.__status !== "deleted"
				);
				break;
			case "properties": {
				data = mergedConfigProperties.value.filter(
					(prop) => !!prop.equipmentId && prop.__status !== "deleted"
				);
				const lorawanProperties = data.filter(
					(prop) =>
						prop.config.protocol === Protocols.LORAWAN_V1_0.value
				);
				const lorawanEquipments = mergedConfigEquipments.value.filter(
					(equipment) =>
						[
							...new Set(
								lorawanProperties.map(
									(prop) => prop.equipmentId
								)
							)
						].includes(equipment.equipmentId)
				);

				const equipmentIds = [
					...new Set(
						lorawanEquipments.map(
							(equipment) => equipment.tags.ws_wizard_equipment_id
						)
					)
				];

				const codecProperties = {};

				for (const equipmentId of equipmentIds) {
					const equipmentProperties =
						await WizardConfigurationService.getEquipmentProperties(
							equipmentId
						);
					codecProperties[equipmentId] = equipmentProperties.data;
				}

				lorawanEquipments.forEach((equipment) => {
					const codecsOfPropertiesAlreadyAdded = [
						...new Set(
							lorawanProperties
								.filter(
									(prop) =>
										prop.equipmentId ===
										equipment.equipmentId
								)
								.map((prop) => prop.config.codecPropertyId)
						)
					];

					const availablePropertyCodecs = codecProperties[
						equipment.tags.ws_wizard_equipment_id
					].map((property) => property.codecPropertyId);

					availablePropertyCodecs.forEach((codec) => {
						if (!codecsOfPropertiesAlreadyAdded.includes(codec)) {
							const property = codecProperties[
								equipment.tags.ws_wizard_equipment_id
							].find(
								(property) => property.codecPropertyId === codec
							);

							data.push({
								equipmentCodecId:
									equipment.tags?.ws_wizard_equipment_id,
								propertyCodecId: property.propertyId,
								equipmentId: equipment.equipmentId,
								name: uppercaseFirstLetter(property.name),
								description: property.description,
								config: {
									protocol: "LORAWAN_V1_0",
									codecPropertyId: codec
								},
								unit: property.unit,
								accessType: property.accessType,
								disabled: true,
								readWrite:
									property.accessType === "REMOTE_WRITE_ONLY"
										? "Write"
										: "Read",
								isSystem: isLorawanSystemCodec(codec),
								tags: {
									ws_property_id: property.propertyId
								}
							});
						}
					});
				});

				break;
			}
			case "redirected-properties":
				data = mergedConfigProperties.value.filter(
					(prop) => !prop.equipmentId && !!prop.gatewayInterfaceId
				);
				break;
			default:
				break;
		}
		return data;
	}
	async function downloadExport() {
		xlsx.cleanup();
		xlsx.allowedHeaders.value = config.value.allowedHeaders;
		const fileData = [];

		const datas = await dataToExport();
		for (const item of datas) {
			const data = await config.value.formatToFile(
				item,
				draftConfig.value
			);
			fileData.push(data);
		}

		const sheetHeaders = config.value.allowedHeaders
			.filter((h) => h !== "errors")
			.map((header) => ({
				header,
				key: config.value.headerToApiKeys[header]
			}));

		const file = await xlsx.generateFile(
			fileData,
			$t(props.selectedExport),
			sheetHeaders,
			hiddenColumns.value
		);

		const nameFile =
			props.selectedExport === "equipments"
				? "equipment"
				: props.selectedExport.toLowerCase();

		downloadFile(
			file,
			`export-bulk-${nameFile}.xlsx`,
			"application/vnd.ms-excel;charset=utf-8;"
		);
	}
	return {
		downloadExport
	};
}
