export const MIN_KIND_VERSION = "5.7.0";
export const MIN_ROUTING_VERSION = "5.7.1";
export const F64_DATA_FORMAT_VERSION = "5.9.0";
export const INT64_LE_DATAFORMAT_VERSION = "5.10.1";
export const MQTT_ACCEPT_WILDCARDS_VERSION = "6.1.3";
export const SCAN_STUCK_TIME_MIN = 2; // following Raul's recomendation, 2 minutes should be enough to consider a scan as stuck (https://wattsense.atlassian.net/browse/FE-1295?focusedCommentId=22244)
export const SCHEDULER_VERSION = "6.4.0";
export const SCHEDULER_APPLICATION_PERIOD_VERSION = "6.5.0";
export const SCHEDULER_EXCEPTIONS_VERSION = "6.5.0";
export const AUTOMATION_VERSION_6_8_6 = "6.8.6";
export const AUTOMATION_VERSION = "6.9.0";

export const SCREEN_BREAKPOINTS = {
	mobile: 0,
	tablet: 769,
	deskstop: 1024,
	widescreen: 1216,
	shrinkSidebar: 1366,
	fullHD: 1408
};
