<template>
	<div
		style="font-size: smaller"
		class="under-navbar"
		v-if="isDeviceRoute && scalingActionRequired"
	>
		<ws-notification-message
			type="info"
			show-icon
			icon="info"
			:show-close-button="false"
		>
			<template #title>
				{{
					isPending
						? $t("banner.scaling-to-gateway.title.pending")
						: $t("banner.scaling-to-gateway.title.queued")
				}}
			</template>
			<banner-modbus
				v-if="hasModbusScalingTask"
				:task="scalingTasks[0]"
				@download-properties="downloadProperties"
				@start-migration="startMigration"
				@send-configuration="publishConfig"
			/>
			<banner-bacnet-mqtt
				v-else
				:task="scalingTasks[0]"
				@download-properties="downloadProperties"
				@send-configuration="publishConfig"
			/>
		</ws-notification-message>
	</div>
</template>

<script setup>
import { computed, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useDeviceStore } from "@/store/device";
import DeviceService from "@/services/v1/Device.service.js";
import DeviceModel from "@/models/device.js";
import { useWsToast } from "@/plugins/toast/toast.plugin.js";
import { useExportBulkConfiguration } from "@/composables/useExportBulkConfiguration";
import EventBus from "@/eventbus.js";
import BannerBacnetMqtt from "./components/banner-bacnet-mqtt.vue";
import BannerModbus from "./components/banner-modbus.vue";

const { t: $t } = useI18n();
const route = useRoute();
const { error: $toastError } = useWsToast();
const { downloadExport: download } = useExportBulkConfiguration({
	selectedExport: "properties"
});

const deviceStore = useDeviceStore();

const isDeviceRoute = computed(
	() => route.name.search(/^(box|hub)-v1/gi) !== -1
);
const deviceId = computed(() => route.params.id);

const scalingTasks = computed(() =>
	deviceStore.scalingTasks.filter((task) =>
		["PENDING", "QUEUED"].includes(task.status)
	)
);
const scalingActionRequired = computed(() => scalingTasks.value.length);

const isPending = computed(
	() => scalingTasks.value && scalingTasks.value[0]?.status === "PENDING"
);

const hasModbusScalingTask = computed(() => {
	return !!scalingTasks.value.find(
		(task) => task.type?.type === "MODBUS_SCALING_MIGRATION"
	)?.id;
});

onMounted(async () => {
	// when the configuration is recreated, revert migration process
	EventBus.$on("deviceConfigurationRecreated", async () => {
		await revertMigration();
	});

	// activate eventbus listener on configuration updated to refresh configuration and remove banner without needing to reload the page
	EventBus.$on("configurationUpdated", async () => {
		setTimeout(async () => {
			await deviceStore.getTasks();
		}, 500);
	});
});

onUnmounted(() => {
	EventBus.$off("deviceConfigurationRecreated");
	EventBus.$off("configurationUpdated");
});

async function downloadProperties() {
	return await download();
}
async function startMigration() {
	try {
		await deviceStore.queueScalingMigration(scalingTasks.value[0]?.id);
		EventBus.$emit("footer-buttons-reload-configuration");
		await deviceStore.getTasks();
	} catch (error) {
		$toastError("failed queueMigration", error);
	}
}
async function revertMigration() {
	EventBus.$emit("footer-buttons-reload-configuration");
	await deviceStore.getTasks();
}

async function publishConfig() {
	const isOnline = await isDeviceOnline();
	if (!isOnline) {
		$toastError(
			$t(
				"views.box.views.configuration.components.footer-buttons.cant-send-configuration-box-not-online"
			)
		);
		return;
	}

	// emit event so footer buttons open modal to publish draft
	EventBus.$emit("footer-buttons-open-modal-publish-draft");
}

async function isDeviceOnline() {
	const _device = await DeviceService.getDevice(deviceId.value, false);
	const device = new DeviceModel(_device);
	return device.status === "ONLINE";
}
</script>
<style lang="scss" scoped>
.under-navbar {
	padding: 0.5rem 1rem;
	background-color: $color-grey-300;
}

// The Hover of the subtle button has been modified > change the background from grey-200 to 5%transparency of the grey-700
:deep(button.is-grey.is-outlined:hover) {
	background-color: rgba($color-grey-700, 0.05) !important;
}
</style>
