<template>
	<div v-if="devicesToMigrate.length && showBanner">
		<ws-notification-message
			type="info"
			class="m-0 p-3"
			is-full-screen
			show-icon
			icon="info"
			show-close-button
		>
			<template #title>
				{{ $t("banner.scaling-to-gateway.title.pending") }}
			</template>
			<i18n-t
				keypath="banner.scaling-to-gateway.body"
				tag="span"
				scope="global"
			>
				<template #devices>
					<template
						v-for="(device, idx) of devicesToMigrate"
						:key="device.deviceId"
					>
						<ws-text-link
							class="is-paddingless"
							is-link
							:show-icon="false"
							@click="getRouteBox(device)"
						>
							{{ device.deviceName }}
						</ws-text-link>
						<span v-if="idx < devicesToMigrate.length - 1">, </span>
					</template>
				</template>
			</i18n-t>
		</ws-notification-message>
	</div>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import DeviceService from "@/services/v1/Device.service.js";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();
const { push: routerPush } = useRouter();
const route = useRoute();

const availableDevices = ref([]);

onMounted(async () => {
	await getAllDevices();
});

const showBanner = computed(() =>
	["boxes-map", "boxes", "hubs"].includes(route.name)
);
const getAllDevices = async () => {
	availableDevices.value = await DeviceService.getDevices({
		includeActiveTasks: true,
		showSpinner: false
	});
};

const devicesToMigrate = computed(() => {
	return availableDevices.value
		.filter((device) => {
			return (
				device.activeTasks &&
				device.activeTasks.find(
					(task) =>
						[
							"MODBUS_SCALING_MIGRATION",
							"MQTT_BACNET_SCALING_MIGRATION"
						].includes(task.type?.type) &&
						["PENDING"].includes(task.status)
				)
			);
		})
		.map((device) => ({
			...device,
			deviceName: device.name,
			deviceId: device.deviceId
		}));
});

// redirect to config>gateways if modbus, otherwise to config>equipment
const landingRoute = (device) => {
	if (!device) {
		return "box-v1-settings";
	}
	const hasModbusScaling = !!device.activeTasks?.find(
		(task) => task.type?.type === "MODBUS_SCALING_MIGRATION"
	)?.id;

	const prefix =
		device.type === "BRIDGE" || device.type === "CLASSICAL_HUB"
			? "hub-v1"
			: "box-v1";

	let landingPage = hasModbusScaling ? "settings-gateways" : "settings";

	return prefix + "-" + landingPage;
};
const getRouteBox = (device) => {
	routerPush({
		name: landingRoute(device),
		params: { id: device.deviceId }
	});
};
</script>
<style lang="scss" scoped></style>
