<template>
	<i18n-t
		keypath="banner.scaling-to-gateway.modbus.body.queued.1"
		tag="p"
		scope="global"
	>
		<template #downloadBtn>
			<ws-text-link
				class="is-paddingless"
				is-link
				:show-icon="false"
				@click="downloadGatewayProperties()"
			>
				{{
					$t(
						"banner.scaling-to-gateway.modbus.queued-download-properties-btn"
					)
				}}
			</ws-text-link>
		</template>
	</i18n-t>
</template>

<script setup>
import { useDeviceStore } from "@/store/device";
import { unparse } from "papaparse";
import { calculateNextModbusRegisterAddressFromProperty as registerAddressFromProp } from "@/views/box/views/configuration/views/gateways/helpers/helpers.js"; // NOTE: this is a temporary file that will be deleted after all migrations, so no need to move this file upper in the hierarchy
import { downloadFile } from "@/helpers/functions.helper.js";

const deviceStore = useDeviceStore();

async function downloadGatewayProperties() {
	const draftConfig = await deviceStore.getDraftConfiguration();
	const equipmentNameById = draftConfig.gatewayInterfaces.reduce(
		(acc, equipment) => {
			if (equipment.config.protocol !== "MODBUS_IP_GATEWAY") {
				return acc;
			}
			acc[equipment.gatewayInterfaceId] = equipment.name;
			return acc;
		},
		{}
	);
	const modbusGatewayProperties = draftConfig.properties
		.filter((prop) => prop.config.protocol === "MODBUS_IP_GATEWAY")
		.map((property) => {
			return {
				...property,
				equipmentName: equipmentNameById[property.gatewayInterfaceId]
			};
		});

	const finalObj = modbusGatewayProperties.map((prop) => {
		const address = prop.config.modbusRegisterAddress;

		return {
			equipment: prop.equipmentName,
			property: prop.name,
			description: prop.description,
			type: prop.config.modbusDataFormat,
			address,
			"size (bytes)":
				prop.config.modbusDataFormat !== "flag"
					? // minus address to get the size of the register
						// multiplied by 2 because Raul told so
						(registerAddressFromProp(prop) - address) * 2
					: "",
			// when is flag, is always 1 bit (as Raul said)
			"size (bits)": prop.config.modbusDataFormat === "flag" ? 1 : "",
			"register type": prop.config.modbusRegisterType
		};
	});
	const csv = unparse(finalObj);
	downloadFile(csv, "gateway_properties_configuration.csv");
}
</script>
