import i18n from "@/lang/lang.js";
import { $dayjs } from "@/plugins/dayjs/dayjs.plugin.js";
import dayjs from "dayjs";

class SubscriptionYearsError extends Error {
	constructor(message = "cannot calculate subscription years") {
		super(message);
		this.name = "SubscriptionYearsError";
	}
}

/**
 * Return device type Code (HUB, BOX) for ACTIVE devices
 *
 * @param {Device object} device Device object from API
 * @returns string
 */
export function getDeviceTypeCode(device) {
	switch (device.type) {
		case "HUB":
		case "BRIDGE":
			return "HUB";

		case "TOWER":
		case "BOX":
			return "BOX";

		default:
			return null;
	}
}

/**
 * Return device type (Tower, Bridge, Connect Box, Connect Box On Premise) for ACTIVE devices
 *
 * @param {Device object} device Device object from API
 * @returns string
 */
export function getDeviceType(device) {
	switch (device.type) {
		case "HUB":
		case "BRIDGE":
			return i18n.t("client-name-hub");

		case "TOWER":
		case "BOX":
			return i18n.t("client-name-box");

		default:
			return null;
	}
}

export function getSubscriptionEndDate(device) {
	return device?.subscription?.endDate;
}

/**
 * Test date which is included between today and +1 month
 * @param {*} date
 * @returns boolean
 */
export const subscriptionIsExpiring = (date) => {
	return (
		!!date &&
		dayjs(date).isAfter(dayjs(), "day") &&
		dayjs(date).isBefore(dayjs().add(1, "month"))
	);
};

/**
 * Return if date is before today
 * @param {Device object} device Device object from API
 * @returns boolean
 */
export const subscriptionIsExpired = (device) => {
	return !!device && device?.subscription?.isExpired;
};

/**
 * Return if date is included between today and -6 months
 * @param {*} date
 * @returns boolean
 */
export const subscriptionIsExpiredLast6Months = (date) => {
	return (
		!!date &&
		dayjs(date).isSameOrBefore(dayjs(), "day") &&
		dayjs(date).isSameOrAfter(dayjs().subtract(6, "month"))
	);
};

/**
 * Return if date is included between today and -15 days
 * @param {*} date
 * @returns boolean
 */
export const subscriptionIsExpiredRecently = (date) => {
	return (
		!!date &&
		dayjs(date).isSameOrBefore(dayjs(), "day") &&
		dayjs(date).isSameOrAfter(dayjs().subtract(15, "day"))
	);
};

/**
 * This function calculates and returns the number of years of subscription for a given device.
 *
 * @param {Object} device - The device object. It should contain either "managedBillingDuration" or "deviceSubscription.startDate and deviceSubscription.endDate".
 *
 * @returns {number} The number of years of subscription
 */
export function getYearsOfSubscription(device) {
	try {
		const { startDate, endDate, current } = device?.subscription || {};

		if (current?.ratePlanDetails?.durationMonths) {
			return Math.round(current.ratePlanDetails.durationMonths / 12);
		}

		if (!startDate && !endDate) {
			throw new SubscriptionYearsError();
		}

		const yearDiff = $dayjs(endDate).diff($dayjs(startDate), "year", true);
		return Math.round(yearDiff < 0 ? 0 : yearDiff);
	} catch {
		throw new SubscriptionYearsError();
	}
}

/**
 * Get Human Friendly Subscription name for device
 *
 * @param {Device object} device Device object from API
 * @param {Object} ratePlans List of Rate Plans from device organization
 * @returns string
 */
export function getSubscriptionNameFromDevice(device, ratePlans = {}) {
	function capitalizeFirstLetter(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	function makeHuman(planName) {
		return capitalizeFirstLetter(
			planName
				.toLowerCase()
				.replace(/hub/g, "")
				.replace(/box/g, "")
				.replace(/package/g, "")
				.replace(/\d+/g, "")
				.replace(/-/g, " ")
				.replace(/_/g, " ")
				.replace(/ {2,}/g, " ")
				.replace(/mid/g, "Medium")
				.replace(/small/g, "Small")
				.replace(/medium/g, "Medium")
				.replace(/large/g, "Large")
				.replace(/xlarge/g, "XLarge")
				.replace(/bacnet/g, "BACnet")
				.replace(/iot/g, "IoT")
				.trim()
		);
	}

	function removeDeviceTypeName(planName) {
		// regex that will return the planName without any of the words "Tower", "Bridge", "Connect Box", "Connect Box On Premise"
		const regex =
			/\b(Box|Hub|Tower|Bridge|Connect Box|Connect Box On Premise)\b(.*-)?/g;
		return planName.replace(regex, "").trim();
	}

	try {
		const currentSubscriptionRatePlan =
			device?.subscription?.current?.ratePlanDetails;
		if (!currentSubscriptionRatePlan) {
			return null;
		}
		const subscriptionName =
			currentSubscriptionRatePlan?.config?.displayName;
		if (subscriptionName) {
			return removeDeviceTypeName(subscriptionName);
		}

		const ratePlan = currentSubscriptionRatePlan.ratePlan;
		return makeHuman(
			removeDeviceTypeName(ratePlans[ratePlan]?.displayName || ratePlan)
		);
	} catch (error) {
		return null;
	}
}

/**
 * Get Human Friendly Subscription name
 *
 * @export
 * @param {Device object} [obj.device={}] Device object from API
 * @param {Object} [obj.ratePlans={}] List of Rate Plans from device organization
 * @param {Boolean} [obj.showYearsOfSubscription=false] Whether to add years of subscription to the description
 * @returns string
 */
export function getSubscriptionText({
	device = {},
	ratePlans = {},
	showYearsOfSubscription = false
}) {
	let subscriptionText = getSubscriptionNameFromDevice(device, ratePlans);

	if (showYearsOfSubscription) {
		try {
			const yearsSubscription = getYearsOfSubscription(device);
			if (yearsSubscription === 0) {
				return subscriptionText;
			} // avoid "0 years"

			subscriptionText = `${subscriptionText} ${
				yearsSubscription !== null
					? [
							"-",
							yearsSubscription,
							i18n.t("x-years", yearsSubscription)
						].join(" ")
					: ""
			}`;
		} catch {
			// do nothing
		}
	}

	return subscriptionText;
}
