import { useLogMonitoring } from "@/plugins/user-console-monitoring/useLogMonitoring.js";

/**
 * Calculate next modbusRegisterAddress value based on a property
 * @param {int} modbusRegisterAddress
 */
export function calculateNextModbusRegisterAddressFromProperty(property) {
	const dataTypesRegisterSpace = {
		int16: 1,
		int32_abcd: 2,
		int32_badc: 2,
		int32_cdab: 2,
		int32_dcba: 2,
		int64_be: 4,
		int64_le: 4,
		uint16: 1,
		uint32_abcd: 2,
		uint32_badc: 2,
		uint32_cdab: 2,
		uint32_dcba: 2,
		float_abcd: 2,
		float_badc: 2,
		float_cdab: 2,
		float_dcba: 2,
		f32_abcd: 2,
		f32_badc: 2,
		f32_cdab: 2,
		f32_dcba: 2,
		f64_be: 4,
		f64_le: 4,
		uint64_be: 4,
		uint64_le: 4,
		flag: 1,
		raw_bytes: property.config.modbusNumberOfRawRegisters || 0,
		text: property.config.modbusNumberOfRawRegisters || 0
	};
	const dataFormat = property.config.modbusDataFormat;
	const registerAddress = property.config.modbusRegisterAddress;

	// in case the modbusDataFormat doesn't exist in dataTypesRegisterSpace, notify and return the registerAddress + 4 (the 4 is a "random" value I decided based on the values of the list dataTypesRegisterSpace - highest value)
	if (!dataTypesRegisterSpace[dataFormat]) {
		useLogMonitoring().notify(
			`[Modbus Gateway Property] Trying to get a dataType that is not in the code: ${dataFormat}`
		);

		return (registerAddress || 0) + 4;
	}

	return dataTypesRegisterSpace[dataFormat] + registerAddress;
}
