<template>
	<div id="global-banners">
		<unpaired-devices-banner />
		<scaling-to-gateways />
	</div>
	<div class="app" v-if="userAuthenticated">
		<div class="sidebar">
			<sidebar />
		</div>
		<div class="app-content">
			<div class="m-2" style="font-size: smaller" v-show="hasRvlBanner">
				<rvl-migration-banner ref="rvlMigrationBanner" />
			</div>
			<navbar @is-ready="() => (navbarReady = true)" />

			<max-properties-banner />
			<scaling-action-required />

			<slot v-if="navbarReady" />
		</div>

		<modal-global-search />

		<chat-launcher />
	</div>
</template>

<script>
import MaxPropertiesBanner from "./components/max-properties-banner/max-properties-banner.vue";
import Sidebar from "./components/sidebar/sidebar.vue";
import Navbar from "./components/navbar/navbar.vue";
import ModalGlobalSearch from "./components/global-search/modal-global-search.vue";
import RvlMigrationBanner from "./components/rvl-migration-banner/rvl-migration-banner.vue";
import UnpairedDevicesBanner from "./components/unpaired-devices-banner/unpaired-devices-banner.vue";
import ScalingToGateways from "./components/scaling-to-gateways/scaling-to-gateways.vue";
import ScalingActionRequired from "./components/scaling-action-required/scaling-action-required.vue";
import AuthService from "@/services/Auth.service.js";
import ChatLauncher from "./components/chat-launcher/zendesk.vue";

import { mapStores } from "pinia";
import { useDeviceStore } from "@/store/device";

export default {
	name: "LayoutApp",

	data() {
		return {
			navbarReady: false, // this is needed because of demo account to load the navbar with <div id="customNavbarTitle">
			userAuthenticated: false,
			hasUnpairedDevices: false,
			showUnpairedDevices: false,
			user: null
		};
	},

	async mounted() {
		try {
			// this is a hack to avoid showing anything that would "crash" because user is not logged in
			this.user = await AuthService.getUserInfo();
			this.userAuthenticated = true;
			// init session tracker
			this.$initKeepSession();
		} catch (error) {
			this.$log.error(
				"Ops! You're not logged in, sorry! You'll be redirected to login page in a few seconds!",
				error
			);
		}
	},

	computed: {
		...mapStores(useDeviceStore),
		hasRvlBanner() {
			return this.deviceStore.oldRvlEquipments?.length;
		}
	},

	components: {
		Sidebar,
		Navbar,
		ModalGlobalSearch,
		ChatLauncher,
		RvlMigrationBanner,
		MaxPropertiesBanner,
		UnpairedDevicesBanner,
		ScalingToGateways,
		ScalingActionRequired
	}
};
</script>

<style lang="scss" scoped>
.app {
	overflow: hidden;
	display: flex;
	height: 100vh;
	background-color: $background;

	.sidebar {
		display: flex;
		flex-shrink: 0;
	}

	.app-content {
		background-color: $background;
		flex: 1 1 0%;
		overflow-x: auto;
		overflow-y: scroll;

		&:focus {
			outline: 2px solid transparent;
			outline-offset: 2px;
		}
	}
}
</style>
