const PropertiesV1Settings = () =>
	import("../box/views/configuration/views/properties/properties.vue");
const PropertiesV1Gateways = () =>
	import(
		"../box/views/configuration/views/properties/properties-gateways.vue"
	);
const EquipmentV1Settings = () =>
	import("../box/views/configuration/views/equipment/equipment-list.vue");
const EquipmentBulkCreation = () =>
	import("../box/views/equipment-bulk-creation/equipment-bulk-creation.vue");
const EquipmentAdd = () =>
	import(
		"../box/views/configuration/views/equipment/views/equipment-add.vue"
	);
const BulkConfiguration = () =>
	import(
		"../box/views/configuration/views/equipment/views/bulk-configuration/bulk-configuration.vue"
	);
const AutoDiscoveryV1Settings = () =>
	import("../box/views/configuration/views/autodiscovery/auto-discovery.vue");
const NetworkV1Settings = () =>
	import("../box/views/configuration/views/networks/network-list.vue");
const ConnectorV1Settings = () =>
	import("../box/views/configuration/views/connectors/connectors-list.vue");
const GatewayInterfacesSettings = () =>
	import("../box/views/configuration/views/gateways/gateways.vue");
const WizardGateway = () =>
	import("../box/views/configuration/views/gateways/wizard-gateway.vue");
const WizardConfiguration = () =>
	import(
		"../box/views/configuration/views/wizard-configuration/wizard-configuration.vue"
	);
const LiveData = () => import("../box/views/live-data/live-data.vue");
const LogsEvents = () => import("../box/views/logs/views/events.vue");
const LogsCommands = () => import("../box/views/logs/views/commands.vue");
const LogsProperties = () => import("../box/views/logs/views/properties.vue");
const MbusScan = () =>
	import("../box/views/configuration/views/mbus-scan/mbus-scan.vue");
const RoutingSettings = () =>
	import("../box/views/configuration/views/routing/routing-list.vue");
const PropertiesBulkCreation = () =>
	import(
		"../box/views/configuration/views/properties/views/bulk/modbus/modbus-properties-bulk-creation.vue"
	);

export default [
	{
		path: "/hubs/v1/:id",
		name: "hub-v1",
		meta: {
			requiresAuth: true,
			deviceType: "hub"
		},
		component: () => import("./hub.vue"),
		children: [
			{
				// it will render this component on routes like: /hubs/v1/{boxid} or  /hubs/v1/{boxid}/settings
				alias: ["", "/hubs/v1/:id"],
				name: "hub-v1-settings-all",
				path: "settings",
				component: () => import("./layout/settings.vue"),
				children: [
					{
						// it will render this component on routes like: /hubs/v1/{boxid}, /hubs/v1/{boxid}/settings, /hubs/v1/{boxid}/settings/equipments or /hubs/v1/{boxid}/equipments
						alias: ["", "/hubs/v1/:id"],
						name: "hub-v1-settings",
						path: "equipments",
						components: {
							settings: EquipmentV1Settings
						}
					},
					{
						name: "hub-v1-settings-equipments",
						path: "equipments",
						components: {
							settings: EquipmentV1Settings
						}
					},
					{
						name: "hub-v1-settings-equipements-add",
						path: "equipments/add",
						components: {
							settings: EquipmentAdd
						}
					},
					{
						name: "hub-v1-settings-bulk-update-config",
						path: "equipments/bulk-configuration",
						components: {
							settings: BulkConfiguration
						}
					},
					{
						name: "hub-v1-settings-properties",
						path: "equipments/:equipmentId/properties",
						redirect: {
							name: "hub-v1-settings-properties-list"
						},
						children: [
							{
								path: "",
								name: "hub-v1-settings-properties-list",
								components: {
									settings: PropertiesV1Settings
								}
							},
							{
								path: "bulk",
								name: "hub-v1-settings-properties-bulk-creation",
								components: {
									settings: PropertiesBulkCreation
								}
							}
						]
					},
					{
						name: "hub-v1-settings-equipment-bulk-creation",
						path: "equipments/bulk-creation",
						components: {
							settings: EquipmentBulkCreation
						}
					},
					{
						name: "hub-v1-settings-wizard-configuration",
						path: "wizard-configuration",
						components: {
							settings: WizardConfiguration
						}
					},
					{
						name: "hub-v1-settings-networks",
						path: "networks",
						components: {
							settings: NetworkV1Settings
						}
					},
					{
						name: "hub-v1-settings-network-autodiscovery",
						path: "networks/:networkId/autodiscovery",
						components: {
							settings: AutoDiscoveryV1Settings
						}
					},
					{
						name: "hub-v1-settings-network-mbus-scan",
						path: "networks/:networkId/mbus-scan",
						components: {
							settings: MbusScan
						}
					},
					{
						name: "hub-v1-settings-connectors",
						path: "connectors",
						components: {
							settings: ConnectorV1Settings
						}
					},
					{
						name: "hub-v1-settings-gateways",
						path: "gateways",
						components: {
							settings: GatewayInterfacesSettings
						}
					},
					{
						name: "hub-v1-settings-gateways-properties",
						path: "gateways/:gatewayId/properties",
						components: {
							settings: PropertiesV1Gateways
						}
					},
					{
						name: "hub-v1-settings-gateways-wizard",
						path: "gateways/:gatewayId/wizard-gateway",
						components: {
							settings: WizardGateway
						}
					},
					{
						name: "hub-v1-settings-routing",
						path: "routing",
						components: {
							settings: RoutingSettings
						}
					},

					{
						path: "scheduling",
						name: "hub-v1-settings-scheduling-all",
						components: {
							settings: () =>
								import(
									"../box/views/scheduling/scheduling-all.vue"
								)
						},
						children: [
							{
								alias: [""],
								name: "hub-v1-settings-scheduling",
								path: "",
								components: {
									view: () =>
										import(
											"../box/views/scheduling/components/scheduling.vue"
										)
								}
							},
							{
								name: "hub-v1-settings-scheduling-add",
								path: "add",
								components: {
									view: () =>
										import(
											"../box/views/scheduling/components/schedule-edit.vue"
										)
								}
							},
							{
								name: "hub-v1-settings-scheduling-edit",
								path: "edit/:scheduleId",
								components: {
									view: () =>
										import(
											"../box/views/scheduling/components/schedule-edit.vue"
										)
								}
							}
						]
					}
				]
			},
			{
				name: "hub-v1-live-data",
				path: "live-data",
				component: LiveData
			},
			{
				name: "hub-v1-logs",
				path: "logs",
				component: () => import("../box/views/logs/logs.vue"),
				props: {
					type: "hub"
				},
				redirect: {
					name: "hub-v1-logs-properties"
				},
				children: [
					{
						alias: "",
						name: "hub-v1-logs-events",
						path: "events",
						components: {
							logsView: LogsEvents
						}
					},
					{
						name: "hub-v1-logs-commands",
						path: "commands",
						components: {
							logsView: LogsCommands
						}
					},
					{
						name: "hub-v1-logs-properties",
						path: "properties",
						components: {
							logsView: LogsProperties
						}
					}
				]
			},
			{
				name: "hub-v1-program",
				path: "program",
				component: () => import("../box/views/program/program.vue"),
				redirect: {
					name: "hub-v1-program-scheduling"
				},
				props: {
					type: "hub"
				},
				children: [
					{
						alias: [""],
						name: "hub-v1-program-scheduling-all",
						path: "schedule",
						components: {
							programView: () =>
								import(
									"../box/views/scheduling/scheduling-all.vue"
								)
						},
						children: [
							{
								alias: [""],
								name: "hub-v1-program-scheduling",
								path: "",
								components: {
									view: () =>
										import(
											"../box/views/scheduling/components/scheduling.vue"
										)
								}
							},
							{
								name: "hub-v1-program-scheduling-add",
								path: "add",
								components: {
									view: () =>
										import(
											"../box/views/scheduling/components/schedule-edit.vue"
										)
								}
							},
							{
								name: "hub-v1-program-scheduling-edit",
								path: "edit/:scheduleId",
								components: {
									view: () =>
										import(
											"../box/views/scheduling/components/schedule-edit.vue"
										)
								}
							}
						]
					},
					{
						name: "hub-v1-program-automation",
						path: "automation",
						components: {
							programView: () =>
								import("../box/views/automation/automation.vue")
						}
					}
				]
			},
			{
				name: "hub-v1-heating-optimiser",
				path: "heating-optimiser",
				component: () =>
					import(
						"../box/views/heating-optimiser/heating-optimiser.vue"
					)
			}
		]
	},
	{
		name: "hub-v1-automation-add",
		path: "/hubs/v1/:id/program/automation/add",
		component: () => import("../box/views/automation/scenario-edit.vue"),
		meta: {
			requiresAuth: true,
			layout: "full-screen"
		}
	},
	{
		name: "hub-v1-automation-edit",
		path: "/hubs/v1/:id/program/automation/:automationId",
		component: () => import("../box/views/automation/scenario-edit.vue"),
		meta: {
			requiresAuth: true,
			layout: "full-screen"
		}
	}
];
