<template>
	<div role="navigation" aria-label="main navigation">
		<!-- Mobile -->
		<div
			class="sidebar-mobile is-hidden-tablet"
			data-testid="sidebar-mobile"
		>
			<sidebar-mobile
				:show-menu="showMobileSidebar"
				@toggle-show-menu="toggleMenuMobile"
			>
				<sidebar-items :is-expanded="true" />
			</sidebar-mobile>
		</div>
		<!-- Desktop -->
		<div
			class="sidebar is-hidden-mobile"
			data-testid="sidebar-desktop"
			:class="{ expanded }"
		>
			<sidebar-items
				:is-expanded="expanded"
				@toggle-expansion="toggleExpansion"
			/>
		</div>
	</div>
</template>

<script setup>
import { useBreakpoints } from "@vueuse/core";
import SidebarItems from "./sidebar-items.vue";
import SidebarMobile from "./sidebar-mobile.vue";
import Eventbus from "@/eventbus.js";
import { onMounted, onUnmounted, provide, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { SCREEN_BREAKPOINTS } from "@/helpers/constants.js";

const breakpoints = useBreakpoints(SCREEN_BREAKPOINTS);
const router = useRouter();

const showMobileSidebar = ref(false);
const expanded = ref(true);
const shrinkSidebar = breakpoints.smallerOrEqual("shrinkSidebar");

function toggleExpansion() {
	expanded.value = !expanded.value;
	window.dispatchEvent(new Event("resize"));
	localStorage.setItem("sidebarExpanded", expanded.value ? "true" : "false");
}

function toggleMenuMobile() {
	showMobileSidebar.value = !showMobileSidebar.value;
}

function forceClose() {
	expanded.value = false;
	localStorage.setItem("sidebarExpanded", expanded.value ? "true" : "false");
}

onMounted(() => {
	Eventbus.$on("toggleSidebarMenu", () => {
		toggleMenuMobile();
	});
	if (
		localStorage.getItem("sidebarExpanded") === "false" ||
		shrinkSidebar.value
	) {
		forceClose();
	}
});

/**
 * If browser size get smaller than breakpoint,
 * force close sidebar
 */
watch(shrinkSidebar, (oldBp, newBp) => {
	if (oldBp !== newBp && !newBp) {
		forceClose();
	}
});

/**
 * After any navigation, smaller than breakpoint,
 * force close sidebar to keep content view
 */
router.afterEach((to, from) => {
	if (to.name !== from.name && shrinkSidebar.value) {
		forceClose();
	}
});

provide("expansion", {
	expanded,
	toggleExpansion
});

onUnmounted(() => {
	Eventbus.$off("toggleSidebarMenu", () => {
		toggleMenuMobile();
	});
});
</script>

<style lang="scss" scoped>
.sidebar.expanded,
.sidebar-mobile {
	max-width: 217px;
}

.sidebar {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	height: 100%;
	width: 100%;
	max-width: 75px;
	border-right: 1px solid $color-grey-300;

	&.expanded {
		hr {
			margin: 1.5rem;
		}
	}
}
</style>
